.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

 
@media(max-width: 560px){
    #reactplayer {
      width: 40vh;
      height: 150px;
  }
  .wrapper{
    width: 40vh;
    height: 150px;
  }

}