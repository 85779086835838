#subtitle{
    margin-bottom:10vh;
}
 #waveform{
    max-width: 760px;
    height: 25px;
}

#waveformblock{
    max-width: 1200px;
}
/* @media(max-width: 1950px) {
    #waveform{
        width:20vw;
        height:20px; 
    }
}  */

@media(max-width: 1440px) {
    #waveform{
        width:60vw;
        height:30px; 
    }
} 

@media(min-width: 1440px) {
    #waveform{
        width:20vw;
        height:20px; 
    } 
}

@media(max-width: 1200px) {
    #waveform{
        max-width:45vw;
        height:20px; 
    }
}

@media(max-width: 800px) {
    #waveform{
        width: 40vw;
        height:15px; 
    }
}

@media(max-width: 620px) {
    #title{
        font-size: 12px;;
    }
    
    #waveform{
        max-width:28vw;
        height:20px; 
    }
    #rectangle{
        width:45px;
        height: 25px;
     }
     /* #title{ 
        position:relative; 
        padding-left:5px,;
        white-space: nowrap;
        overflow:hidden; 
     } */
     /* #imgpodcast{
        display:none; 
    }  */
 
}

@media(max-width: 450px) {
    #podcast{
        grid-template-columns:none;
        padding-left: 10px;
    }
    #waveform{
        width:38vw;
        height:20px;
        
        white-space: nowrap;
        
    }
    
    #rectangle{
        width:45px;
        height: 25px;
        /* display:none;  */
     }
    #title{ 
    
        white-space: wrap;
        overflow:hidden; 
        display: block;
        opacity: 1; 
        width: 100%;
        position: relative;
        padding-left: 2%;
     }
    #imgpodcast{
        display:none; 
    } 
 }  